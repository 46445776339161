
<app-header-video  
                [backgroundVideo]="'https://s3.eu-west-2.amazonaws.com/iac.media/images/contact.mp4'"
                [contentOffsetToTop]="true"
                [title]="'Order Online - Indian Aroma'"
                [desc]="'Best Indian Restaurant & Takeaway in Gants Hill, Ilford IG2 6UF'"
                [isHomePage]="false"
                [fullscreen]="false" style="height:10vh">
</app-header-video>

<div class="px-3">
  <div class="theme-container">
    <mat-card class="main-content-header mb-5">
      <div class="p-3">
        <div fxLayout="row wrap" fxLayoutAlign="space-around">
          <div fxFlex="100" fxFlex.gt-xs="33.3">
            <div
              fxLayout="column"
              fxLayoutAlign="center center"
              class="text-center"
            >
              <mat-icon color="primary" class="mat-icon-lg">home</mat-icon>
              <h3 class="primary-color py-1">ADDRESS :</h3>
              <span
                ><a href="https://goo.gl/maps/JuVK89DfJqGLLjh18" target="_blank"
                  >33 Woodford Avenue Gants Hill, Ilford IG2 6UF England</a
                ></span
              >
            </div>
          </div>
          <div fxFlex="100" fxFlex.gt-xs="33.3" ngClass.xs="mt-2">
            <div fxLayout="column" fxLayoutAlign="center center">
                <button (click)="openCart()" class="order_online" mat-raised-button fxShow.gt-xs="true">
                    Order Online
                  </button>     
            </div>
          </div>
          
          <div fxFlex="100" fxFlex.gt-xs="33.3" ngClass.xs="mt-2">
            <div fxLayout="column" fxLayoutAlign="center center">
              <mat-icon color="primary" class="mat-icon-lg">shopping_cart</mat-icon>
              <h3 class="primary-color py-1">For Pick up / Delivery Orders:</h3>
              <span><a href="tel:+442085507777">(+44) 0 208 550 7777</a> </span>
              <span><a href="tel:+447916507777">(+44) 0 791 650 7777</a> </span>     
            </div>
          </div>
          
        </div>

        
      </div>
    </mat-card>
  </div>
</div>



<!-- <app-header-image *ngIf="settings.header == 'image'"
  [backgroundImage]="'assets/images/others/book.png'"
  [bgImageAnimate]="true"
  [contentOffsetToTop]="true"
  [title]="'Book A Table'"
  [isHomePage]="false"
  [fullscreen]="false"
>
</app-header-image> -->
<app-header-image
  [backgroundImage]="'assets/images/others/book.png'"
  [bgImageAnimate]="false"
  [contentOffsetToTop]="true"
  [title]="'Book Table'"
  
>
</app-header-image>
<!-- <app-header-video  *ngIf="settings.header == 'video'"
                [backgroundVideo]="'https://s3.eu-west-2.amazonaws.com/iac.media/images/tablebooking.mp4'"
                [contentOffsetToTop]="true"
                [title]="'Book Table'"
                
                [isHomePage]="false"
                [fullscreen]="false" style="height:10vh">
</app-header-video> -->

<div class="px-3">
  <div class="theme-container pb-7">
    <mat-card class="main-content-header">
      <h1 class="fw-500 text-center mb-4">RESERVE YOUR TABLE NOW</h1>
      <!-- <h3 class="fw-500 text-center mb-4"></h3>  -->
      <div class="reservation-form-footer">
        For reservations, please call the restaurant directly at<a
          href="tel:+442085507777"
        >
          <span style="color: #b71c1c">(+44) 208 550 7777</span>
        </a>
        /
        <a href="tel:+447916507777">
          <span style="color: #b71c1c">(+44) 791 650 7777</span>
          .</a
        >
        
      </div>
<br/>
      <h1 class="fw-500 text-center mb-4">PRIVATE DINING & EVENTS</h1>
      <!-- <h3 class="fw-500 text-center mb-4"></h3>  -->
      <div class="reservation-form-footer">
        The restaurant can also be hired for private functions. Whatever your event or celebration, we are here to make your special day a truly memorable one.
       
        Please
        <a routerLink="/contact">
          <span style="color: #b71c1c">contact us</span>
        </a>
        for information about group reservations, private events, and Christmas
        parties.
      </div>

      <!-- <app-reservation-form (onFormSubmit)="onMakeReservation($event)"></app-reservation-form>   -->
    </mat-card>
    <mat-card class="p-0 o-hidden" fxLayout="row wrap"> 
      <div fxFlex="100" fxFlex.gt-sm="100" fxFlex.sm="100">
          <img src="assets/images/others/about-1.jpg" alt="about" class="mw-100 d-block">
      </div>         
  </mat-card>
  </div>
</div>
 

<!-- <app-get-app></app-get-app> -->

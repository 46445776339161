<app-header-video *ngIf="settings.header == 'video'"
    [backgroundVideo]="'https://s3.eu-west-2.amazonaws.com/iac.media/images/gallery.mp4'" [contentOffsetToTop]="true"
    [title]="'Market Food Stalls'" [isHomePage]="false" [fullscreen]="false" style="height:10vh">
</app-header-video>

<div class="px-3">
    <div class="theme-container">
        <mat-sidenav-container class="all-menu-items mt-3">
            <mat-sidenav-content>
             

                <!-- <div fxLayout="row wrap" class="poster-details" style="margin-top: 50px;" *ngFor="let stall of upCommingStalls">
                    <div fxFlex="100" fxFlex.gt-sm="30" class="py-3 f">
                        <img *ngIf="stall?.posterType === 'image'" (click)="openDialog(stall?.poster)" [src]="stall?.poster" alt="about-1" class="mw-100" style="height: 600px;">
                        <video #videoElement class="mw-100" controls *ngIf="stall?.posterType === 'video'" [src]="stall?.poster"></video>

                    </div>
                    <div fxFlex="100" fxFlex.gt-sm="70" class="py-3 content-div" [ngClass.gt-sm]="(settings.rtl) ? 'pr-5' : 'pl-5'">
                         <div class="w-100 flex">
                            <a  mat-button class="secondary-font primary-color text-center button-google-map" style="text-wrap: wrap !important;" href="{{stall?.googleMapLocation}}" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="Location"><path fill="#b71c1c" d="M50.4,18.41a18.4,18.4,0,1,0-36.8,0C13.6,28.57,32,54.26,32,54.26S50.4,28.57,50.4,18.41ZM20,18.41a12,12,0,1,1,12,12A12,12,0,0,1,20,18.41Z" class="color1e252d svgShape"></path><path fill="#b71c1c" d="M41.26,43c-1.38,2.25-2.72,4.33-3.9,6.11,5,.68,8.5,2.28,8.5,4.16,0,2.48-6.2,4.5-13.86,4.5s-13.86-2-13.86-4.5c0-1.89,3.52-3.49,8.51-4.16-1.18-1.78-2.52-3.87-3.9-6.12C10.65,44.36,1.91,48.42,1.91,53.23,1.91,59.18,15.38,64,32,64s30.09-4.82,30.09-10.77C62.09,48.42,53.35,44.36,41.26,43Z" class="color1e252d svgShape"></path></svg>
                                {{stall?.location}}
                            </a>
                         </div>
                        
                       
                        
                   <div class="buttons">
                            <div class="one-in-row">
                                <button mat-button color="primary"  (click)="orderOnline()" class="uppercase">
                                    <mat-icon>shopping_cart</mat-icon>
                                    Order Online
                                </button>
                                 <button mat-button color="primary" class="uppercase" [routerLink]="['/reservation']" >
                                    <mat-icon>supervised_user_circle</mat-icon>
                                    Book Table
                                </button>
                            </div>
                           
                            <div class="one-in-row" style="margin-top: 20px ;">
                                <a mat-button color="primary" class="uppercase" href="tel:+442085507777" target="_blank">
                                    <mat-icon>phone</mat-icon>
                                    Call Us</a>

                                    <a mat-button color="primary" class="uppercase" href="{{soicalLinkss.whatsAppLink}}" target="_blank">
                                        <img src="../../../assets/images/icons/whatsapp-svg.svg" style="height: 20px; width: 30px;" alt="">
                                        Whatsapp Us
                                    </a>
                                    
                                <a mat-button color="primary" class="uppercase" href="mailto:contact@indianaroma.co.uk" target="_blank">
                                    <mat-icon> email</mat-icon>
                                    Email Us</a>
                            </div>

                            <div class="one-in-row">
                                <a mat-button color="primary" class="uppercase" href="https://www.indianaromacaterers.co.uk/" target="_blank">
                                <mat-icon>open_in_browser</mat-icon>
                                Visit Catering Website
                            </a>
                            </div>
                         </div>

                    </div>
                </div> -->


                <div fxLayout="row wrap" class="mt-5 poster-details" *ngFor="let stall of upCommingStalls">
                    <div
                      fxFlex="100"
                      fxFlex.gt-sm="50"
                      class="py-3"
                      [ngClass.gt-sm]="settings.rtl ? 'pl-5' : 'pr-5'"
                    >
                      <h1 *ngIf="!isSuccessful" class="secondary-font primary-color text-center mb-3">
                       Register For Offers
                      </h1>

                      <div class="thank-you" *ngIf="isSuccessful">
                        <h1 class="secondary-font primary-color text-center mb-1">
                          Hi, {{registerForm.value.name}}
                         </h1>

                        <h2 class="secondary-font primary-color text-center mb-1">
                          Thank you for registering with us. 
                         </h2>
                        
                        <p>
                          We will keep you updated with our latest offers and events.
                        </p>
                        
                        <div class="buttons">
                          <div class="one-in-row">
                              <button mat-button color="primary"  (click)="orderOnline()" class="uppercase">
                                  <mat-icon>shopping_cart</mat-icon>
                                  Order Online
                              </button>
                               <button mat-button color="primary" class="uppercase" [routerLink]="['/reservation']" >
                                  <mat-icon>supervised_user_circle</mat-icon>
                                  Book Table
                              </button>
                          </div>
                         
                          <div class="one-in-row" style="margin-top: 20px ;">
                              <a mat-button color="primary" class="uppercase" href="tel:+442085507777" target="_blank">
                                  <mat-icon>phone</mat-icon>
                                  Call Us</a>

                                  <a mat-button color="primary" class="uppercase" href="{{soicalLinkss.whatsAppLink}}" target="_blank">
                                      <img src="../../../assets/images/icons/whatsapp-svg.svg" style="height: 20px; width: 30px;" alt="">
                                      Whatsapp Us
                                  </a>
                                  
                              <a mat-button color="primary" class="uppercase" href="mailto:contact@indianaroma.co.uk" target="_blank">
                                  <mat-icon> email</mat-icon>
                                  Email Us</a>
                          </div>

                          <div class="one-in-row">
                              <a mat-button color="primary" class="uppercase" href="https://www.indianaromacaterers.co.uk/" target="_blank">
                              <mat-icon>open_in_browser</mat-icon>
                              Visit Catering Website
                          </a>
                          </div>
                       </div>
                         
                      </div>
                      
          
                      <form *ngIf="!isSuccessful" [formGroup]="registerForm" fxLayout="row wrap">
                        <div fxFlex="100">
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Name</mat-label>
                            <input
                              matInput
                              placeholder="Name"
                              formControlName="name"
                              required
                            />
                            <mat-error *ngIf="registerForm.controls.name.errors?.required"
                              >Name is required</mat-error
                            >
                          </mat-form-field>
                        </div>
                        <div fxFlex="100">
                          <mat-form-field
                            appearance="outline"
                            class="w-100"
                            [hideRequiredMarker]="true"
                          >
                            <mat-label>Email *</mat-label>
                            <input
                              matInput
                              placeholder="Email"
                              formControlName="email"
                              required
                              
                            />
                            <mat-error *ngIf="registerForm.controls.email.errors?.required"
                              >Email is required</mat-error
                            >
                            <mat-error
                              *ngIf="registerForm.controls.email.hasError('invalidEmail')"
                              >Invalid email address</mat-error
                            >
                          </mat-form-field>
                        </div>
                        <div fxFlex="100">
                          <mat-form-field
                            appearance="outline"
                            class="w-100"
                            [hideRequiredMarker]="true"
                          >
                            <mat-label>Phone *</mat-label>
                            <input
                              matInput
                              placeholder="Phone"
                              formControlName="phone"
                              
                            />
                            <mat-error
                            *ngIf="registerForm.controls.phone.errors?.required"
                              >
                              Phone Number is required
                              </mat-error
                            >

                            <mat-error
                              *ngIf="registerForm.controls.phone.hasError('invalidPhone')"
                              >Invalid phone Number</mat-error
                            >
                          </mat-form-field>
                        </div>


                        <div fxFlex="100">
                            <mat-form-field
                              appearance="outline"
                              class="w-100"
                              [hideRequiredMarker]="true"
                            >
                              <mat-label>Street Address *</mat-label>
                              <input
                                matInput
                                placeholder="Address"
                                formControlName="streetAddress"
                                
                              />
                              <mat-error
                              *ngIf="registerForm.controls.streetAddress.errors?.required"
                                >
                                Street Address is required
                                </mat-error
                              >
                            </mat-form-field>
                          </div>


                          <div fxFlex="100">
                            <mat-form-field
                              appearance="outline"
                              class="w-100"
                              [hideRequiredMarker]="true"
                            >
                              <mat-label>City / Town *</mat-label>
                              <input
                                matInput
                                placeholder="City / Town"
                                formControlName="city"
                                
                              />
                              <mat-error
                              *ngIf="registerForm.controls.city.errors?.required"
                                >
                                Street Address is required
                                </mat-error
                              >
                            </mat-form-field>
                          </div>

                          <div fxFlex="100">
                            <mat-form-field
                              appearance="outline"
                              class="w-100"
                              [hideRequiredMarker]="true"
                            >
                              <mat-label>Post Code *</mat-label>
                              <input
                                matInput
                                placeholder="Post Code"
                                formControlName="postelCode"
                                
                              />
                              <mat-error
                              *ngIf="registerForm.controls.postelCode.errors?.required"
                                >
                                Post Code is required
                                </mat-error
                              >
                            </mat-form-field>
                          </div>
          
                        <mat-slide-toggle
                        formControlName="isNewsLetterAcceptedOnContact"
                        name="isNewsLetterAcceptedOnContact"
                        >Receive Newsletter</mat-slide-toggle
                      >
                       
          
                        <div class="w-100 py-2 text-center">
                          <button
                            mat-raised-button
                            color="primary"
                            type="submit"
                            class="uppercase"
                            (click)="onRegisterFormSubmit()"
                            >
                            Register
                          </button>
                          <mat-error class="mt-2" *ngIf="message && !isSuccessful">
                            {{message}}
                          </mat-error>
                       
          
                          <!-- [disabled]="!registerForm.valid" -->
          
                        </div>
                      </form>
          
                    
                      <hr *ngIf="!isSuccessful"  class="mt-4" />
                      <p *ngIf="!isSuccessful" class="mt-4 terms">
                        By clicking the "Register" button you agree with our
                        <a href="/terms-of-service">Terms</a> and
                        <a href="/privacy-policy">Privacy Policy.</a>
                      </p>
                    </div>
                    <div fxFlex="100" fxFlex.gt-sm="50" class="py-3" style="position: relative;">
                      <!-- <div class="coupon-card">
                        <div >
                          <div class="card-header">
                            <mat-icon class="offer-icon">local_offer</mat-icon>
                            <h3 class="coupon-title"  style="margin-bottom: 0px !important;">Exclusive Offer</h3>
                          
                          </div>
                          <div class="coupon-expiration">
                            register now and get ₤5 off on your first order
                          </div>
                        </div>
                        <div class="coupon-body">
                          <div class="coupon-info">
                            <p class="available-text" style="margin-bottom: 0px !important; margin-top: 0px; ">Remaing Coupons</p>
                            <div class="coupon-count-wrapper">
                              <span class="coupon-count">{{remaingCouponCount < 0 ? 0 : remaingCouponCount}}</span>
                              <div class="blinking-dot" *ngIf="remaingCouponCount > 0"></div>
                            </div>
                          </div>
                          
                        </div>
                       
                      </div> -->
                      
                      
                    
                      <img  class="mw-100 mb-2 full-image" *ngIf="stall?.posterType === 'image'" (click)="openDialog(stall?.poster)" [src]="stall?.poster" alt="about-1" class="mw-100 h-600">
                      <video  class="mw-100 mb-2" #videoElement controls *ngIf="stall?.posterType === 'video'" [src]="stall?.poster"></video>
          
                      <!-- <div class="location">
                        <a  mat-button class="secondary-font primary-color text-center button-google-map" style="text-wrap: wrap !important;" href="{{stall?.googleMapLocation}}" target="_blank">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="Location"><path fill="#b71c1c" d="M50.4,18.41a18.4,18.4,0,1,0-36.8,0C13.6,28.57,32,54.26,32,54.26S50.4,28.57,50.4,18.41ZM20,18.41a12,12,0,1,1,12,12A12,12,0,0,1,20,18.41Z" class="color1e252d svgShape"></path><path fill="#b71c1c" d="M41.26,43c-1.38,2.25-2.72,4.33-3.9,6.11,5,.68,8.5,2.28,8.5,4.16,0,2.48-6.2,4.5-13.86,4.5s-13.86-2-13.86-4.5c0-1.89,3.52-3.49,8.51-4.16-1.18-1.78-2.52-3.87-3.9-6.12C10.65,44.36,1.91,48.42,1.91,53.23,1.91,59.18,15.38,64,32,64s30.09-4.82,30.09-10.77C62.09,48.42,53.35,44.36,41.26,43Z" class="color1e252d svgShape"></path></svg>
                          {{stall?.location}}
                      </a>
                      </div> -->
                      <div style="justify-content: center;" class="socialicon mt-3">
                          <a href="https://api.whatsapp.com/send/?phone=%2B447916507777&text&type=phone_number&app_absent=0" target="_blank">
                              <img src="../../../assets/images/icons/contact icons/whatsapp.svg" />
                          </a>
                          
                          <a href="https://www.facebook.com/indianaromalondon" target="_blank">
                              <img src="../../../assets/images/icons/contact icons/fb.svg" />
                          </a>
                          <a href="https://www.instagram.com/indian_aroma_london/" target="_blank">
                              <img src="../../../assets/images/icons/contact icons/insta.svg" />
          
                          </a>
                          <a href="https://www.tripadvisor.co.uk/Restaurant_Review-g580420-d10331967-Reviews-Indian_Aroma_Restaurant-Ilford_Greater_London_England.html" target="_blank">
                              <img src="../../../assets/images/icons/contact icons/tripad.svg" />
          
                          </a>
                          <a href="https://www.youtube.com/channel/UCc4bUg_a2W-7W_gkYiqEgRg" target="_blank">
                              <img src="../../../assets/images/icons/contact icons/youtube.svg" />
          
                          </a>
                          <a href="https://www.google.com/maps/place/Indian+Aroma/@51.5775013,0.0647723,17z/data=!3m1!4b1!4m5!3m4!1s0x47d8a71c3d1efde7:0x87110a0c2ece69c9!8m2!3d51.5775013!4d0.0647723" target="_blank">
                              <img src="../../../assets/images/icons/contact icons/g plus.svg" />
          
                          </a>
                          <a href="https://twitter.com/IndianAroma_LDN" target="_blank">
                            <img src="../../../assets/images/icons/contact icons/twitter.svg" />
                        </a>
                        <a href="https://www.pinterest.co.uk/indianaromalondon/" target="_blank">
                          <img src="../../../assets/images/icons/contact icons/printest.svg"  style="width: 19px;margin-top:-12px;"/>
                      </a>
                      <a href="https://www.linkedin.com/company/indian-aroma-cuisine-limited/" target="_blank">
                        <img src="../../../assets/images/linked.png"  style="width: 19px;margin-top:-12px;"/>
                    </a>
                      </div>
                      <app-social-icons
                        [iconSize]="'lg'"
                        [iconColor]="'primary'"
                        fxLayout="row"
                        fxLayoutAlign="end center"
                      ></app-social-icons>
          
                      
                    </div>
          
                    
                  </div>
                  
                <div fxLayout="row wrap" class="poster-details" style="margin-top: 50px;" *ngIf="!upCommingStalls.length">
                    <div fxFlex="100" fxFlex.gt-sm="100" class="py-3 content-div" [ngClass.gt-sm]="(settings.rtl) ? 'pr-5' : 'pl-5'">
                         <div class="w-100 flex" style="font-size: 30px;">
                            No Upcoming Stalls
                         </div>
                                

                    </div>
                </div>


                <div class="px-3 mt-5">  
                    <div class="theme-container"> 
                        <mat-sidenav-container class="all-menu-items mt-3">
                          
                
                            <mat-sidenav-content [class.distance]="sidenavOpen">
                                <div fxLayout="row wrap" class="all-menu-items-wrapper mt-0">
                                    <div fxFlex="100" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center" fxShow="true" fxShow.gt-sm class="px-2 mb-3" >
                                        <mat-button-toggle-group class="list-group" #group="matButtonToggleGroup" appearance="legacy" [value]="selectedCategoryId" class="categories-toogle-group mt-2" (change)="onChangeCategory($event)">
                                            <mat-button-toggle [value]="2" class="letter" >
                                                Market Stalls
                                            </mat-button-toggle>
                                          <mat-button-toggle [value]="0" class="letter" >
                                                Restaurant Gallery
                                            </mat-button-toggle>
                                            <mat-button-toggle [value]="1" class="letter" >
                                                Catering Gallery
                                            </mat-button-toggle>
                                        </mat-button-toggle-group>


                                        <mat-button-toggle-group *ngIf="selectedCategoryId == 2" class="list-group" #group="matButtonToggleGroup" appearance="legacy" [value]="subMenu" class="categories-toogle-group mt-2" (change)="onChangeSubMenu($event)">
                                            <mat-button-toggle [value]="1" class="letter" >
                                                Pictures
                                            </mat-button-toggle>
                                          
                                            <mat-button-toggle [value]="2" class="letter" >
                                                Videos
                                            </mat-button-toggle>

                                        </mat-button-toggle-group>
                                    </div>
                                 
                                   

                                    <ng-container *ngIf="selectedCategoryId == 2 && subMenu == 2" >
                                        <div *ngFor="let stall of getPrevStallsVideos()" [fxFlex]="(viewType=='grid') ? viewCol : 100"  class="item">  
                                            <mat-card class="menu-item p-0" [fxLayout]="(viewType == 'grid') ? 'column' : 'row'" [ngClass]="[viewType + '-item', 'column-'+column]">   
                                                <div fxFlex="100" [fxShrink]="(viewType == 'grid') ? '3' : '2'" class="thumbnail-section">
                                                    <div class="card-image-video">
                                                        <video style="height: 500px !important; object-fit: cover;"   class="w-100 d-block swiper-lazy" #videoElement controls [src]="stall?.poster"></video>
                                                    </div>  
                                                </div>  
                                            </mat-card>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="selectedCategoryId == 2 && subMenu == 1" >
                                        <div *ngFor="let stall of getPrevStallsImages()" [fxFlex]="(viewType=='grid') ? viewCol : 100"  class="item">  
                                            <app-gallery-menu [objectFit]="'cover'"  (click)="openDialog(stall?.poster)"  [menuItem]="stall?.poster" [viewType]="viewType" [viewColChanged]="viewCol"></app-gallery-menu>
                                        </div>
                                    </ng-container>

                               
                                    <ng-container *ngIf="selectedCategoryId == 0" >
                                        <div *ngFor="let menuItem of ResmenuItems" [fxFlex]="(viewType=='grid') ? viewCol : 100"  class="item">  
                                            <app-gallery-menu  [menuItem]="menuItem" [viewType]="viewType" [viewColChanged]="viewCol"></app-gallery-menu>
                                            
                                        </div>
                                    </ng-container>
                                    
                                    <ng-container *ngIf="selectedCategoryId == 1">
                                        <div class="reservation-form-footer">
                                            <p>Our catering services are very unique. We provide a truly authentic Indian food for all occasions. We have an excellent team of trained chefs and staff members who will work with you in understanding your requirements, giving you options and delivering the best Indian food.</p>
                                            <div fxLayout="row" fxLayoutAlign="center center">
                                                <a mat-raised-button href="https://www.indianaromacaterers.co.uk" color="primary" class="uppercase">Explore our Catering</a>
                                            </div>  
                
                                        
                                          <p>Our customers have been extremely happy with our services and the wide range of menu we offer.We have the top food hygiene rating that we are proud of and are continuing to uphold.</p>
                                          <div fxLayout="row" fxLayoutAlign="center center">
                                            <a mat-raised-button href="https://www.indianaromacaterers.co.uk/our-gallery" color="primary" class="uppercase">View Catering Gallery</a>
                                        </div> 
                                            <p>We are one of the very few Indian restaurants in the UK who has adopted recyclable packaging and have fully eliminated plastic materials.</p>
                
                                         
                                            
                                          </div>
                                    </ng-container>
                                    
                                    
                        
                                    <div *ngIf="menuItems && menuItems.length == 0" fxFlex="100" fxLayoutAlign="center center" [style.min-height.px]="200">                
                                        <mat-spinner *ngIf="!message"></mat-spinner>
                                        <mat-chip-listbox *ngIf="message">
                                            <mat-chip selected disableRipple="true" color="warn" class="uppercase ws-nowrap">
                                                {{message}}
                                            </mat-chip> 
                                        </mat-chip-listbox> 
                                    </div> 
                                    
                                    
                                    <!-- <app-testimonials class="mt-5 mb-5"></app-testimonials>   -->
                                </div>
                            </mat-sidenav-content>
                        </mat-sidenav-container> 
                    </div>
                </div>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</div>
<app-get-in-touch></app-get-in-touch>
<!-- <app-header-image
  [backgroundImage]="'assets/images/others/contact.jpg'"
  [bgImageAnimate]="false"
  [contentOffsetToTop]="true"
  [title]="'Contact - Indian Aroma'"
  [desc]="'Best Indian Restaurant & Takeaway in Gants Hill, Ilford IG2 6UF'"
>
</app-header-image> -->
<app-header-video  *ngIf="settings.header == 'video'"
                [backgroundVideo]="'https://s3.eu-west-2.amazonaws.com/iac.media/images/contact.mp4'"
                [contentOffsetToTop]="true"
                [title]="'Contact - Indian Aroma'"
                [desc]="'Best Indian Restaurant & Takeaway in Gants Hill, Ilford IG2 6UF'"
                [isHomePage]="false"
                [fullscreen]="false" style="height:10vh">
</app-header-video>

<div class="px-3">
  <div class="theme-container">
    <mat-card class="main-content-header mb-5">
      <div class="p-3">
        <div fxLayout="row wrap" fxLayoutAlign="space-around">
          <div fxFlex="100" fxFlex.gt-xs="20">
            <div
              fxLayout="column"
              fxLayoutAlign="center center"
              class="text-center"
            >
              <mat-icon color="primary" class="mat-icon-lg">home</mat-icon>
              <h3 class="primary-color py-1">ADDRESS :</h3>
              <span
              ><a [href]="restaurantAddress.mapStreetViewLink" target="_blank"
              >{{restaurantAddress.label}}</a
                ></span
              >
            </div>
          </div>
          <div fxFlex="100" fxFlex.gt-xs="20" ngClass.xs="mt-2">
            <div fxLayout="column" fxLayoutAlign="center center">
              <mat-icon color="primary" class="mat-icon-lg">schedule</mat-icon>
              <h3 class="primary-color py-1">Opening Hours :</h3>
              <!-- <span><a>Mon(New year Special): 11 AM -11 PM</a> </span> -->
              <span><a>Mon-Sun: 5 PM - 10:30 PM</a> </span> 
            </div>
          </div>
          <div fxFlex="100" fxFlex.gt-xs="20" ngClass.xs="mt-2">
            <div fxLayout="column" fxLayoutAlign="center center">
              <mat-icon color="primary" class="mat-icon-lg">call</mat-icon>
              <h3 class="primary-color py-1">PHONES :</h3>
              <span><a href="tel:+442085507777">(+44) 0 208 550 7777</a> </span>
              <span><a href="tel:+447916507777">(+44) 0 791 650 7777</a> </span>     
            </div>
          </div>
          <div fxFlex="100" fxFlex.gt-xs="20" ngClass.xs="mt-2">
            <div fxLayout="column" fxLayoutAlign="center center">
              <mat-icon color="primary" class="mat-icon-lg"
                >mail_outline</mat-icon
              >
              <h3 class="primary-color py-1">E-MAIL :</h3>
              <span
                ><a href="mailto:info@indianaroma.co.uk" target="_blank"
                  >info&#64;indianaroma.co.uk</a
                >
              </span>
              <a href="mailto:contact@indianaroma.co.uk" target="_blank"
                >contact&#64;indianaroma.co.uk</a
              >
              <a href="mailto:bookings@indianaroma.co.uk" target="_blank"
                >bookings&#64;indianaroma.co.uk</a
              >
            </div>
          </div>
        </div>

        <div fxLayout="row wrap" class="mt-5">
          <div
            fxFlex="100"
            fxFlex.gt-sm="50"
            class="py-3"
            [ngClass.gt-sm]="settings.rtl ? 'pl-5' : 'pr-5'"
          >
            <h1 class="secondary-font primary-color text-center mb-3">
              Get In Touch With Us <!-- {{isNewsLetterAcceptedOnContact}} -->
            </h1>
            <div class="text-center">
              <p class="m-0">
                Anything On Your Mind. We’ll Be Glad To Assist You!
              </p>
              <p>
                Submit the form below or mail us
                <mat-icon color="primary" class="mat-icon-sm mr-1 ml-1"
                  >mail_outline</mat-icon
                >

                <a href="mailto:contact@indianaroma.co.uk" class="red"
                  >contact&#64;indianaroma.co.uk</a
                >
              </p>
            </div>

            <form [formGroup]="contactForm" fxLayout="row wrap">
              <div fxFlex="100">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Name</mat-label>
                  <input
                    matInput
                    placeholder="Name"
                    formControlName="name"
                    required
                  />
                  <mat-error *ngIf="contactForm.controls.name.errors?.required"
                    >Name is required</mat-error
                  >
                </mat-form-field>
              </div>
              <div fxFlex="100">
                <mat-form-field
                  appearance="outline"
                  class="w-100"
                  [hideRequiredMarker]="true"
                >
                  <mat-label>Email</mat-label>
                  <input
                    matInput
                    placeholder="Email"
                    formControlName="email"
                    required
                    
                  />
                  <mat-error *ngIf="contactForm.controls.email.errors?.required"
                    >Email is required</mat-error
                  >
                  <mat-error
                    *ngIf="contactForm.controls.email.hasError('invalidEmail')"
                    >Invalid email address</mat-error
                  >
                </mat-form-field>
              </div>
              <div fxFlex="100">
                <mat-form-field
                  appearance="outline"
                  class="w-100"
                  [hideRequiredMarker]="true"
                >
                  <mat-label>Phone</mat-label>
                  <input
                    matInput
                    placeholder="Phone"
                    formControlName="phone"
                    
                  />
                  <!-- <mat-error *ngIf="contactForm.controls.phone.errors?.required"
                    >Phone is required</mat-error
                  > -->
                  <mat-error
                    *ngIf="contactForm.controls.phone.hasError('invalidPhone')"
                    >Invalid phone Number</mat-error
                  >
                </mat-form-field>
              </div>
              <div fxFlex="100">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Message</mat-label>
                  <textarea
                    matInput
                    placeholder="Message"
                    formControlName="message"
                    required
                    rows="5"
                  ></textarea>
                  <mat-error
                    *ngIf="contactForm.controls.message.errors?.required"
                    >Message is required</mat-error
                  >
                </mat-form-field>
              </div>

              <mat-slide-toggle
              formControlName="isNewsLetterAcceptedOnContact"
              name="isNewsLetterAcceptedOnContact"
              >Receive Newsletter</mat-slide-toggle
            >
              <!-- <mat-slide-toggle
              [(ngModel)]="isNewsLetterAcceptedOnContact"
              name="isNewsLetterAcceptedOnContact"
              >Receive Newsletter</mat-slide-toggle
            > -->
      

              <div class="w-100 py-2 text-center">
                <button
                  mat-raised-button
                  color="primary"
                  type="submit"
                  class="uppercase"
                  (click)="onContactFormSubmit()"
                  >
                  Submit
                </button>
             

                <!-- [disabled]="!contactForm.valid" -->

              </div>
            </form>

          
            <hr class="mt-4" />
            <p class="mt-4 terms">
              By clicking the "Submit" button you agree with our
              <a href="/terms-of-service">Terms</a> and
              <a href="/privacy-policy">Privacy Policy.</a>
            </p>
          </div>
          <div fxFlex="100" fxFlex.gt-sm="50" class="py-3">
            <img
              src="assets/images/others/about-1.jpg"
              alt="about-2"
              class="mw-100 mb-4"
            />
            <div class="socialicon">
                <a href="https://api.whatsapp.com/send/?phone=%2B447916507777&text&type=phone_number&app_absent=0" target="_blank">
                    <img src="../../../assets/images/icons/contact icons/whatsapp.svg" />
                </a>
                
                <a href="https://www.facebook.com/indianaromalondon" target="_blank">
                    <img src="../../../assets/images/icons/contact icons/fb.svg" />
                </a>
                <a href="https://www.instagram.com/indian_aroma_london/" target="_blank">
                    <img src="../../../assets/images/icons/contact icons/insta.svg" />

                </a>
                <a href="https://www.tripadvisor.co.uk/Restaurant_Review-g580420-d10331967-Reviews-Indian_Aroma_Restaurant-Ilford_Greater_London_England.html" target="_blank">
                    <img src="../../../assets/images/icons/contact icons/tripad.svg" />

                </a>
                <a href="https://www.youtube.com/channel/UCc4bUg_a2W-7W_gkYiqEgRg" target="_blank">
                    <img src="../../../assets/images/icons/contact icons/youtube.svg" />

                </a>
                <a href="https://www.google.com/maps/place/Indian+Aroma/@51.5775013,0.0647723,17z/data=!3m1!4b1!4m5!3m4!1s0x47d8a71c3d1efde7:0x87110a0c2ece69c9!8m2!3d51.5775013!4d0.0647723" target="_blank">
                    <img src="../../../assets/images/icons/contact icons/g plus.svg" />

                </a>
                <a href="https://twitter.com/IndianAroma_LDN" target="_blank">
                  <img src="../../../assets/images/icons/contact icons/twitter.svg" />
              </a>
              <a href="https://www.pinterest.co.uk/indianaromalondon/" target="_blank">
                <img src="../../../assets/images/icons/contact icons/printest.svg"  style="width: 19px;margin-top:-12px;"/>
            </a>
            <a href="https://www.linkedin.com/company/indian-aroma-cuisine-limited/" target="_blank">
              <img src="../../../assets/images/linked.png"  style="width: 19px;margin-top:-12px;"/>
          </a>
            </div>
            <app-social-icons
              [iconSize]="'lg'"
              [iconColor]="'primary'"
              fxLayout="row"
              fxLayoutAlign="end center"
            ></app-social-icons>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>

<div class="contact-map">
  <iframe
    class="map"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2479.494421795278!2d0.0625836157725634!3d51.57750127964681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a6ea02a68f01%3A0x3f9f7a97f7ff7b5a!2s33%20Woodford%20Ave%2C%20Gants%20Hill%2C%20Ilford%20IG2%206UF!5e0!3m2!1sen!2suk!4v1645459915806!5m2!1sen!2suk"
  ></iframe>
</div>

<!-- <app-get-app></app-get-app>  -->

<app-header-image *ngIf="settings.header == 'image'" 
                [backgroundImage]="'assets/images/others/homepage.jpg'"
                [bgImageAnimate]="true"
                [contentOffsetToTop]="true"
                [title]="'Welcome to Restaurant'"
                [desc]="'Booking a table online is easy'"
                [isHomePage]="true"
                [fullscreen]="false">
</app-header-image> 

<app-header-carousel *ngIf="settings.header == 'carousel'" 
                    [slides]="slides" 
                    [contentOffsetToTop]="true"
                    [fullscreen]="true">
</app-header-carousel>

<app-header-video *ngIf="settings.header == 'video'" 
                [backgroundVideo]="'https://s3.eu-west-2.amazonaws.com/iac.media/images/video.mp4'"
                [contentOffsetToTop]="true"
                
                [isHomePage]="true"
                [fullscreen]="true" style="height:10vh">
</app-header-video>
  
<app-our-awards></app-our-awards> 

<div class="section mt-3">
    <div class="px-3">
        <div class="theme-container">
            <h1 class="section-title">Authentic Indian Restaurant</h1> 
            <p class="section-desc text-muted">Welcome To Indian Aroma</p>
            <mat-card class="p-0 o-hidden" fxLayout="row wrap"> 
                <div fxFlex="100" fxFlex.gt-sm="50" fxFlex.sm="50">
                    <img src="assets/images/others/about-us.jpg" alt="about" class="mw-100 d-block">
                </div> 
                <div fxFlex="100" fxFlex.gt-sm="50" fxFlex.sm="50" class="py-3 px-4">
                    <h1 class="secondary-font primary-color mb-2">Jewel of Indian tastes</h1>
                    <p> At Indian Aroma, we are dedicated about serving food that are truly Indian, ensuring all our customers enjoy and appreciate a genuine India’s culinary culture.                       
                    </p>
                    <p>Our experienced and talented chefs offer amazing dishes that you can enjoy from the comfort of your own home through our takeaway and delivery services.
                    </p>
                   <p>Our dishes are always freshly prepared and have exquisite flavours of Indian multi cuisine. 
                    We have expertly transformed our dishes with special recipes that will entice you with the authentic flavours of India.
                    They will assist you in savouring the flavour and take you over the length and breadth of the Indian subcontinent, allowing you to experience the rich diversity of Indian culinary customs, different regional flavours, and vibrant local culture.
                </p>
                <!-- <p>Our restaurant is a true destination for fans of Indian cuisine. Curry lovers will savour the wholesome ethnic Indian foods we serve, which include rich lamb saffron Biryani and spicy, fragrant curries.</p> -->
                    <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="pt-3">
                        <!-- <img src="assets/images/others/signature.png" alt="signature">  -->
                        <a class="logo" routerLink="/"><app-logo></app-logo></a>
                        <a mat-raised-button routerLink="/about" color="primary" class="uppercase">Our Delicious Story</a>
                    </div> 
                </div>  
            </mat-card>
        </div>
    </div>   
</div>



<div class="section default mt-3">
    <div class="px-3">
        <div class="theme-container">
            <h1 class="section-title">Our Customers Favourites</h1>
            <p class="section-desc text-muted">Choose one of these popular flavours, if you're not ready to commit to a new flavour! you'll be pleased you did.</p> 
            <app-menu-items-carousel [menuItems]="specialMenuItems"></app-menu-items-carousel>
            <div fxLayout="row" fxLayoutAlign="center center">
                <a mat-raised-button routerLink="/menu" color="primary" class="uppercase">Explore our menus</a>
            </div>  
        </div>
    </div>   
</div> 

<div class="section default mt-3">
    <div class="px-3">
        <div class="theme-container">
            <h1 class="section-title">Our Chef's Special</h1>
            <p class="section-desc text-muted">The best dishes of the city for our clients.</p> 
            <app-menu-items-carousel [menuItems]="bestMenuItems"></app-menu-items-carousel>
            <div fxLayout="row" fxLayoutAlign="center center">
                <a mat-raised-button routerLink="/reservation" color="primary" class="uppercase">Book Table</a>
            </div>  
        </div>
    </div>   
</div>  


<app-delivery-partners></app-delivery-partners>




<app-our-services></app-our-services> 
<app-our-chefs></app-our-chefs>  



<div class="section">
    <div class="px-3">
        <div class="theme-container">
            <h1 class="section-title mb-3">Make a Reservation</h1>
            <p class="section-desc text-muted">
                Our new menu and recipes have marked Indian Aroma as a unique and refreshing place to dine. Please call us to make a reservation on
                <span><a href="tel:+442085507777">(+44) 0 208 550 7777</a> </span> / 
                <span><a href="tel:+447916507777">(+44) 0 791 650 7777</a> </span>.                 
                <br/>
                We are open Mon-Sun: 5 PM - 10:30 PM.
            </p>

        </div>
    </div>   
</div>   

<app-timeline></app-timeline> 

<app-testimonials></app-testimonials>  
<!-- <app-get-app></app-get-app> -->


<app-get-in-touch></app-get-in-touch>
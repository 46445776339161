import { Component, OnInit, OnDestroy, ViewChildren, QueryList, ElementRef, AfterViewInit, ViewChild, SimpleChange } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings, Settings } from 'src/app/app.settings';
import { socialLinks } from 'src/app/constants/app.constants';
import { ImageViewerDialogComponent } from './image-viewer/image-viewer.component';
import { DataService } from 'src/app/core/services/data.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { emailValidator, phnNumValidator } from 'src/app/theme/utils/app-validators';
import { AppService } from 'src/app/app.service';
import { Subscription, filter, map } from 'rxjs';
import { MenuItem, Pagination } from 'src/app/app.models';
import { MatPaginator } from '@angular/material/paginator';

import { MediaChange, MediaObserver } from '@ngbracket/ngx-layout';

@Component({
  selector: 'app-market-stall',
  templateUrl: './market-stall.component.html',
  styleUrls: ['./market-stall.component.scss']
})
export class MarketStallComponent implements OnInit, OnDestroy, AfterViewInit {
  public settings: Settings;
  soicalLinkss = socialLinks;
  upCommingStalls: any[] = [];
  prevStalls: any[] = [];
  public registerForm!: UntypedFormGroup;

  @ViewChildren('videoElement') videoElements?: QueryList<ElementRef>;

  private observer?: IntersectionObserver;


  @ViewChild('sidenav') sidenav: any;
  public sidenavOpen:boolean = false;
  public showSidenavToggle:boolean = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public menuItems: MenuItem[] = [];
  public categories:any;
  public viewType: string = 'grid';
  public viewCol: number = 25;
  public count: number = 12;
  public sort: string = '';
  public selectedCategoryId:number = 2;
  public pagination:Pagination = new Pagination(1, this.count, null, 2, 0, 0); 
  public message:string | null = '';
  public isSuccessful = false;
  public watcher: Subscription;
  ResmenuItems: any[]=[];
  public column:number = 4;
  subMenu = 1;
  constructor(private appSettings: AppSettings, public dialog: MatDialog, public data: DataService,
    public formBuilder: UntypedFormBuilder,
    public appService: AppService,
     public mediaObserver: MediaObserver
  ) {
    // this.updateCouponCount();
    this.settings = this.appSettings.settings; 
    this.watcher = mediaObserver.asObservable()
    .pipe(filter((changes: MediaChange[]) => changes.length > 0), map((changes: MediaChange[]) => changes[0]))
    .subscribe((change: MediaChange) => {
      if(change.mqAlias == 'xs') {
        this.sidenavOpen = false;
        this.showSidenavToggle = true;
        this.viewCol = 100;
      }
      else if(change.mqAlias == 'sm'){
        this.sidenavOpen = false;
        this.showSidenavToggle = true;
        this.viewCol = 50;
      }
      else if(change.mqAlias == 'md'){
        this.sidenavOpen = false;
        this.showSidenavToggle = false;
        this.viewCol = 33.3;
      }
      else{
        this.sidenavOpen = false;
        this.showSidenavToggle = false;
        this.viewCol = 25;
      }
    });

  }

  remaingCouponCount: any;
  intervalOneSecond: any;
  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      name: ["", Validators.required],
      email: ["", Validators.compose([Validators.required, emailValidator])],
      phone: ["",Validators.compose([Validators.required, phnNumValidator])],
      streetAddress: ["", Validators.required],
      city: ["", Validators.required],
      postelCode: ["", Validators.required],
      isNewsLetterAcceptedOnContact:[true]
    });

    this.data.getMarketStalls().subscribe((res: any) => {
      this.upCommingStalls = res?.upcomingStalls?.slice(0,1) || [];
      this.prevStalls = res?.previousStalls || [];
    }, err => {
      console.error(err);
    });
    this.getCategories();
    this.getMenuItems();
    this.getResMenuImg();
  }

  async ngAfterViewInit() {
    // this.setupObserver();
    // this.videoElements?.changes.subscribe((videos: QueryList<ElementRef>) => {
    //   videos.forEach(video => {
    //     this.observer?.observe(video.nativeElement);
    //   });
    // });
    // this.intervalOneSecond = setInterval(() => {
    //   this.updateCouponCount();
    // }, 1000);
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}){  
    if(changes.viewColChanged){
      this.getColumnCount(changes.viewColChanged.currentValue); 
    }   
  }

  public getColumnCount(value:number){
    if(value == 25){
      this.column = 4;
    }
    else if(value == 33.3){
      this.column = 3;
    }
    else if(value == 50){
      this.column = 2
    }
    else{
      this.column = 1;
    }
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
    if (this.intervalOneSecond) {
      clearInterval(this.intervalOneSecond);
    }
  }

//  async updateCouponCount () {
//     await this.data.getRemainingCouponCount().then((res) => {
//       this.remaingCouponCount = res?.remainingCoupons;
//     }
//     ).catch(err => {
//       console.error(err);
//     });
//   }


  openDialog(imageSrc: string): void {
    this.dialog.open(ImageViewerDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: { imgSrc: imageSrc },
      panelClass: 'full-screen-modal'
    });
  }
  getPrevStallsVideos(): any[] {
    return this.prevStalls.filter(stall => stall.posterType === 'video');
  }

  getPrevStallsImages(): any[] {
    return this.prevStalls.filter(stall => stall.posterType === 'image');
  }

 async registerCustomer() {
  const json = {
    name: this.registerForm.value.name,
    email: this.registerForm.value.email,
    phone: this.registerForm.value.phone,
    address: this.registerForm.value.streetAddress,
    city: this.registerForm.value.city,
    postalCode: this.registerForm.value.postelCode,
    isSubscribeToNewsLetter: this.registerForm.value.isNewsLetterAcceptedOnContact
  }
  // await this.data.registerCustomerForCoupon(json).then((res) => {
  //   this.message = 'Thank you for registering with us. ';
  //   this.isSuccessful = true;
  // }
  // ).catch(err => {
  //   console.error(err);
  // }
  // );
   await this.data.registerCustomer(json).then((res) => {
      this.message = 'Thank you for registering with us. ';
      this.isSuccessful = true;
    }).catch(err => {
      console.error(err);
      this.isSuccessful = false;
      if(err.error?.error?.includes('duplicate key error') || err.error?.error?.includes('duplicate key error')){
        this.message = 'User already exists.';
      }else{
        this.message = 'Something went wrong. Please try again later.';

      }
    });
  }

  orderOnline(){
    this.data.openCart();
  }


  public onRegisterFormSubmit(): void {
    console.log(this.registerForm.value);

    if (this.registerForm.valid) {
      let contact = this.registerForm.value;
      let data = {
        name: contact.name,
        email: contact.email,
        message: contact.message,
        phone: contact.phone,
      };

      //if subscribe clicked, call newsletter api as well
      if( this.registerForm.value.isNewsLetterAcceptedOnContact){
        this.appService.getSubscription({email:data.email}).subscribe((data:any)=>{
          console.log('subscripton ', data);
        })
      }

      this.registerCustomer()
  }
}




public getCategories(){
  this.appService.getCategories().subscribe(categories=>{
    this.categories = categories;
    this.appService.Data.categories = categories;
  })
} 
public selectCategory(id:number){
  this.selectedCategoryId = id;
  this.menuItems.length = 0;
  this.resetPagination();
  this.getMenuItems();
  this.sidenav.close();
}
public onChangeCategory(event:any){ 
  this.selectCategory(event.value);
}

public onChangeSubMenu(event:any){ 
  this.subMenu = event.value;
}

public getResMenuImg(){
  let object: any[]=[];
  let i: any;
  this.appService.getResImg().subscribe(data => {
  let dat:any  = data;
  
  for(i in dat){
    object=dat[i].img
    
    this.ResmenuItems [i]= ['https://s3.eu-west-2.amazonaws.com/iac.media/'+dat[i].img]
  }
  console.log(this.ResmenuItems);
  
  })
}  

public getMenuItems(){
  this.appService.getgalleryMenuItems().subscribe(data => {
  let dataany:any  = data;
    this.menuItems  = dataany.body;
    
  })
}  

public resetPagination(){ 
  if(this.paginator){
    this.paginator.pageIndex = 0;
  }
  this.pagination = new Pagination(1, this.count, null, null, this.pagination.total, this.pagination.totalPages);
}

public changeCount(count:number){
  this.count = count;   
  this.menuItems.length = 0;
  this.resetPagination();
  this.getMenuItems();
}
public changeSorting(sort:any){    
  this.sort = sort; 
  this.menuItems.length = 0;
  this.getMenuItems();
}
public changeViewType(obj:any){ 
  this.viewType = obj.viewType;
  this.viewCol = obj.viewCol; 
} 


public onPageChange(e:any){ 
  this.pagination.page = e.pageIndex + 1;
  this.getMenuItems();
  window.scrollTo(0,0);  
}
}
